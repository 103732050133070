<template>
    <v-card
        class="mx-auto overflow-auto border-radius0 height80"
        elevation="0"
    >
        <v-card-title class="card-header text-left fixed-card-title2">
            <v-row>
                <v-col cols="10">
                    <v-toolbar-title >{{$t('edit')}} {{$t('hospital')}}</v-toolbar-title>
                </v-col>
              <v-spacer></v-spacer>
              <v-icon @click="closeDrawer" class="white-icon mr-3">mdi-close</v-icon>
            </v-row>
        </v-card-title>
        <v-card-text>
            <v-form
                ref="form"
                v-model="valid"
                lazy-validation
            >
                <!-- Data -->
                <v-container>
                    <v-row class="pt-2">
                        <v-col
                            class="d-flex pa-1"
                            cols="12"
                            sm="12"
                        >
                            <v-text-field
                                outlined
                                hide-details="auto"
                                dense
                                v-model="selectedHospital.hospitalName"
                                :rules="[v => !!v || 'Item is required']"
                                :label="$t('office-name')"
                                required>
                            </v-text-field>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col
                            class="d-flex pa-1"
                            cols="12"
                            sm="12"
                        >
                            <v-select
                                class="width-text-field2"
                                hide-details
                                dense
                                v-model="selectedHospital.medicalInstitutionId"
                                :items="allMedicalInstitutions"
                                item-value="id"
                                item-text="institutionName"
                                :label="$t('medicalInstitution')"
                                outlined
                                @change="setSelectedOrganization"
                                :menu-props="{ offsetY: true }"
                            >
                            </v-select>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col
                            class="d-flex pa-1"
                            cols="12"
                            sm="12"
                        >
                            <v-textarea
                                outlined
                                hide-details
                                dense
                                no-resize
                                v-model="selectedHospital.description"
                                :label="$t('description')"
                            >
                            </v-textarea>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col
                            class="d-flex pa-1"
                            cols="12"
                            sm="12"
                        >
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col
                            class="d-flex pa-1"
                            cols="12"
                            sm="12"
                        >
                            <div class="pt-4">
                                <h5 class="heading text-left">{{$t('address')}}</h5>
                            </div>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col
                            class="d-flex pa-1"
                            cols="12"
                            sm="12"
                        >
                            <v-text-field
                                outlined
                                hide-details="auto"
                                dense
                                v-model="selectedHospital.address"
                                :label="$t('address')"
                                required
                                :rules="[v => !!v || 'Item is required']"
                            >
                            </v-text-field>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col
                            class="d-flex pa-1"
                            cols="6"
                            sm="6"
                        >
                            <v-text-field
                                outlined
                                hide-details="auto"
                                dense
                                v-model="selectedHospital.city"
                                :label="$t('city')"
                                required
                                :rules="[v => !!v || 'Item is required']"
                            >
                            </v-text-field>
                        </v-col>
                        <v-col
                            class="d-flex pa-1"
                            cols="6"
                            sm="6"
                        >
                            <v-text-field
                                outlined
                                hide-details="auto"
                                dense
                                v-model="selectedHospital.state"
                                :label="$t('state')"
                                required
                                :rules="[v => !!v || 'Item is required']"
                            >
                            </v-text-field>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col
                            class="d-flex pa-1"
                            cols="6"
                            sm="6"
                        >
                            <v-text-field
                                outlined
                                hide-details="auto"
                                dense
                                v-model="selectedHospital.zip"
                                :label="$t('zip')"
                                required
                                :rules="[v => !!v || 'Item is required']"
                            >
                            </v-text-field>
                        </v-col>
                        <v-col
                            class="d-flex pa-1"
                            cols="6"
                            sm="6"
                        >
                            <v-text-field
                                outlined
                                hide-details="auto"
                                dense
                                v-model="selectedHospital.country"
                                :label="$t('country')"
                                required
                                :rules="[v => !!v || 'Item is required']"
                            >
                            </v-text-field>
                        </v-col>
                    </v-row>
                  <v-row>
                    <v-col
                        class="d-flex pa-1"
                        cols="12"
                        sm="12"
                    >
                      <div class="pt-4">
                        <h5 class="heading text-left">{{$t('contact')}}</h5>
                      </div>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col
                        class="d-flex pa-1"
                        cols="12"
                        sm="12"
                    >
                      <v-text-field
                          outlined
                          hide-details="auto"
                          dense
                          v-model="selectedHospital.contactPerson"
                          :rules="[v => !!v || 'Item is required']"
                          :label="$t('contact-person')"
                          required
                      >
                      </v-text-field>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col
                        class="d-flex pa-1"
                        cols="12"
                        sm="12"
                    >
                      <v-text-field
                          outlined
                          hide-details="auto"
                          dense
                          v-model="selectedHospital.contactPhone"
                          :rules="[v => !!v || 'Item is required']"
                          :label="$t('contact-phone')"
                          @input="formatPhoneNumber"
                          required
                      >
                      </v-text-field>
                      <v-text-field
                          class="ml-2"
                          outlined
                          hide-details="auto"
                          dense
                          v-model="selectedHospital.contactEmail"
                          :rules="[v => !!v || 'Item is required']"
                          :label="$t('contact-email')"
                          required
                      >
                      </v-text-field>
                    </v-col>
                  </v-row>
                </v-container>
            </v-form>
          <div v-if="ecpLocationMapping !== null && (roleName === 'SYSTEM_ADMIN' || roleName === 'SYSTEM_USER')">
          <v-row>
            <v-col
                class="d-flex pa-1 mt-3"
                cols="6"
                sm="6"
            >
              <div :key="refreshKey" class="d-flex flex-column pl-3">
                <span class="font-weight-bold color-black">{{ $t('migrate-users') }}</span>
                <v-btn-toggle
                    @change="doAutomaticEcpUsersMapping"
                    v-model="ecpLocationMapping.userCreationIsAutomaticValue"
                    row
                    active-class="chips white--text"
                    mandatory
                    class="white"
                >
                  <v-btn
                      class="tabs-class"
                      :value="0"
                      :input-value="ecpLocationMapping.userCreationIsAutomaticValue"
                  >{{$t('manual')}}</v-btn>
                  <v-btn
                      class="tabs-class"
                      :value="1"
                      :input-value="ecpLocationMapping.userCreationIsAutomaticValue"

                  >{{$t('automatic')}}</v-btn>
                </v-btn-toggle>
              </div>
            </v-col>
            <v-col
                class="d-flex pa-1 mt-3"
                cols="6"
                sm="6"
            >
              <div :key="refreshKey" class="d-flex flex-column pl-3">
                <span class="font-weight-bold color-black">{{$t('migrate-residents')}}</span>
                <v-btn-toggle
                    @change="doAutomaticEcpResidentsMapping"
                    v-model="ecpLocationMapping.residentCreationIsAutomaticValue"
                    row
                    active-class="chips white--text"
                    mandatory
                    class="white"
                >
                  <v-btn
                      class="tabs-class"
                      :value="0"
                      :input-value="ecpLocationMapping.residentCreationIsAutomaticValue"
                  >{{$t('manual')}}</v-btn>
                  <v-btn
                      class="tabs-class"
                      :value="1"
                      :input-value="ecpLocationMapping.residentCreationIsAutomaticValue"

                  >{{$t('automatic')}}</v-btn>
                </v-btn-toggle>
              </div>
            </v-col>
          </v-row>
          <v-row v-if="false">
            <v-col
                class="d-flex pa-1 mt-3"
                cols="6"
                sm="6"
            >
              <div :key="refreshKey" class="d-flex flex-column pl-3">
                <span class="font-weight-bold color-black">{{$t('send-measurements-to-emar')}}</span>
                <v-btn-toggle
                    @change="doFinalizeMeasurmentsAutomatically"
                    v-model="ecpLocationMapping.finalizeMeasurementAutomaticallyValue"
                    row
                    active-class="chips white--text"
                    mandatory
                    class="white"
                >
                  <v-btn
                      class="tabs-class"
                      :value="0"
                      :input-value="ecpLocationMapping.finalizeMeasurementAutomaticallyValue"
                  >{{$t('manual')}}</v-btn>
                  <v-btn
                      class="tabs-class"
                      :value="1"
                      :input-value="ecpLocationMapping.finalizeMeasurementAutomaticallyValue"

                  >{{$t('automatic')}}</v-btn>
                </v-btn-toggle>
              </div>
            </v-col>
          </v-row>
          </div>
        </v-card-text>
        <v-card-actions class="fixed-card-actions">
            <v-row class="mb-0">
                <v-col cols="6">
                    <v-btn
                        @click="closeDrawer"
                        elevation="0"
                        class="cancel-button-style capitalize-text"
                        >
                    {{$t('cancel')}}
                    </v-btn>
                </v-col>
                <v-col cols="6">
                    <v-btn
                        :disabled="!valid"
                        @click="saveHospital"
                        class="save-button-style"
                    >
                    {{ $t('save') }}
                    </v-btn>
                </v-col>
            </v-row>
      </v-card-actions>
    </v-card>
</template>

<script>
import { mapGetters, mapState } from 'vuex';
import { showSuccessAlert, showErrorAlert } from '@/utils/utilities';
import { AsYouType } from 'libphonenumber-js';
import HospitalsRepository from '@/data/repositories/v1/HospitalsRepository';
// import { AsYouType } from 'libphonenumber-js';

export default {
    name: 'HospitalEditDrawerDialog',
    components: {},
    data: () => ({
        refreshKey: 0,
        valid: true,
        measurement: 0,
        menu: false,
        hospitalName: '',
        selectedOrganization: null,
        description: '',
        ecpLocationMapping: null,
    }),
    mounted () {
      this.hospitalName = this.selectedHospital?.hospitalName;
      this.description = this.selectedHospital?.description;
      this.getEcpMappingLocation();
    },

    computed: {
        ...mapGetters({
          delay: 'authentication/getDelay',
          appBarSearch: 'filterbar/getSearch',
          appBarMedicalInstitution: 'filterbar/getMedicalInstitution',
          roleName: 'authentication/getRole',
        }),
        ...mapState({
          updateDrawer: (state) => state.updateDrawer,
          allMedicalInstitutions: (state) => state.medicalInstitutions.medicalInstitutions,
          hospitals: (state) => state.hospitals.hospitals,
          allVerifiers: (state) => state.verifiers.allverifires,
          selectedHospital: (state) => state.hospitals.selectedHospital,
		}),
    },
  watch: {
    updateDrawer: {
      handler (newVal) {
        if (newVal) {
          this.getEcpMappingLocation();
        }
      },
      deep: true,
    },
  },
    methods: {
      doAutomaticEcpUsersMapping () {
        var body = {
          hospitalId: this.selectedHospital.id,
          mappEcpUsersAutomatically: this.ecpLocationMapping.userCreationIsAutomaticValue === 1,
        };

        HospitalsRepository.doAutomaticEcpUsersMapping(body).then((res) => {
          this.ecpLocationMapping = res.data;
          this.ecpLocationMapping.userCreationIsAutomaticValue = this.ecpLocationMapping.userCreationIsAutomatic ? 1 : 0;
          this.ecpLocationMapping.residentCreationIsAutomaticValue = this.ecpLocationMapping.residentCreationIsAutomatic ? 1 : 0;
          this.ecpLocationMapping.finalizeMeasurementAutomaticallyValue = this.ecpLocationMapping.finalizeMeasurementAutomatically ? 1 : 0;
          this.refreshKey += 1;
        });
      },

      doAutomaticEcpResidentsMapping () {
        var body = {
          hospitalId: this.selectedHospital.id,
          mappEcpResidentsAutomatically: this.ecpLocationMapping.residentCreationIsAutomaticValue === 1,
        };
        HospitalsRepository.doAutomaticEcpResidentsMapping(body).then((res) => {
          this.ecpLocationMapping = res.data;
          this.ecpLocationMapping.userCreationIsAutomaticValue = this.ecpLocationMapping.userCreationIsAutomatic ? 1 : 0;
          this.ecpLocationMapping.residentCreationIsAutomaticValue = this.ecpLocationMapping.residentCreationIsAutomatic ? 1 : 0;
          this.ecpLocationMapping.finalizeMeasurementAutomaticallyValue = this.ecpLocationMapping.finalizeMeasurementAutomatically ? 1 : 0;
          this.refreshKey += 1;
        });
      },

      doFinalizeMeasurmentsAutomatically () {
        var body = {
          hospitalId: this.selectedHospital.id,
          finalizeMeasurmentsAutomatically: this.ecpLocationMapping.finalizeMeasurementAutomaticallyValue === 1,
        };
        HospitalsRepository.doFinalizeMeasurmentsAutomatically(body).then((res) => {
          this.ecpLocationMapping = res.data;
          this.ecpLocationMapping.userCreationIsAutomaticValue = this.ecpLocationMapping.userCreationIsAutomatic ? 1 : 0;
          this.ecpLocationMapping.residentCreationIsAutomaticValue = this.ecpLocationMapping.residentCreationIsAutomatic ? 1 : 0;
          this.ecpLocationMapping.finalizeMeasurementAutomaticallyValue = this.ecpLocationMapping.finalizeMeasurementAutomatically ? 1 : 0;
          this.refreshKey += 1;
        });
      },
      getEcpMappingLocation () {
        if (this.roleName === 'SYSTEM_ADMIN' || this.roleName === 'SYSTEM_USER') {
        this.$store.dispatch('hospitals/getEcpMappingLocation', this.selectedHospital.id).then((res) => {
          this.ecpLocationMapping = null;
          if (res.data) {
          this.ecpLocationMapping = res.data;
          this.ecpLocationMapping.userCreationIsAutomaticValue = this.ecpLocationMapping.userCreationIsAutomatic ? 1 : 0;
          this.ecpLocationMapping.residentCreationIsAutomaticValue = this.ecpLocationMapping.residentCreationIsAutomatic ? 1 : 0;
          this.ecpLocationMapping.finalizeMeasurementAutomaticallyValue = this.ecpLocationMapping.finalizeMeasurementAutomatically ? 1 : 0;
          }
        });
        }
      },
      formatPhoneNumber (val) {
        this.selectedHospital.contactPhone = new AsYouType().input(val);
      },
      setSelectedOrganization (val) {
        this.selectedOrganization = this.allMedicalInstitutions.find(org => org.id === val);

        this.selectedHospital.address = this.selectedOrganization.address;
        this.selectedHospital.city = this.selectedOrganization.city;
        this.selectedHospital.state = this.selectedOrganization.state;
        this.selectedHospital.zip = this.selectedOrganization.zip;
        this.selectedHospital.country = this.selectedOrganization.country;
      },
        async getHospitals () {
			await this.$store.dispatch('hospitals/getHospitals').then(() => {
				// after filtering
				// const selectedMedicalInstitutionId = this.appBarMedicalInstitution;
				// if (selectedMedicalInstitutionId === 0 || selectedMedicalInstitutionId === undefined || selectedMedicalInstitutionId === null) {
				// 	this.$store.commit('hospitals/GET_HOSPITALS_V2', this.hospitals); // get from the store, not from API call - refresh list with all hospitals
				// } else {
				// 	var hospitalsForMedicalInstitution = this.hospitals?.filter(h => h.medicalInstitutionId === selectedMedicalInstitutionId);
				// 	this.$store.commit('hospitals/GET_HOSPITALS_FILTERED', hospitalsForMedicalInstitution); // filter the hospitals list by selected organization
				// }

                this.closeDrawer();
			});
		},
        async saveHospital () {
          await this.checkAndFixLocationDetails();
            if (this.$refs.form.validate()) {
                const body = {
                    id: this.selectedHospital.id,
                    medicalInstitutionId: this.selectedHospital.medicalInstitutionId,
                    hospitalName: this.selectedHospital.hospitalName,
                    description: this.selectedHospital.description,
                    contactPerson: this.selectedHospital.contactPerson,
                    contactPhone: this.selectedHospital.contactPhone,
                    contactEmail: this.selectedHospital.contactEmail,
                    address: this.selectedHospital?.address,
                    zip: this.selectedHospital?.zip,
                    city: this.selectedHospital?.city,
                    state: this.selectedHospital?.state,
                    country: this.selectedHospital?.country,
                    addressLatitude: this.selectedHospital?.addressLatitude,
                    addressLongitude: this.selectedHospital?.addressLongitude,
                    mediaServerData: this.selectedHospital.mediaServerData,
                    hospitalSpetializationId: this.hospitalSpetializationId,
                };
                await this.$store.dispatch('hospitals/editHospital', body)
                .then((res) => {
                    if (res) {
                        showSuccessAlert(this.$t('success-operation'));
                        this.getHospitals(); // reload the list of organizations
                    } else {
                        showErrorAlert(this.$t('failed-operation'));
                    }
                });
            } else {
                this.$refs.form.validate();
            }
        },
        save (date) {
            this.$refs.menu.save(date);
        },
        closeDrawer () {
            this.$store.commit('SET_UPDATE_DETAILS_DRAWER', false);
            this.$store.commit('SET_EDIT_DETAILS_DRAWER', false);
        },
        async checkAndFixLocationDetails () {
            try {
                if (this.selectedHospital.address !== '' && this.selectedHospital.city !== '' && this.selectedHospital.zip !== '' && this.selectedHospital.state !== '' && this.selectedHospital.country !== '') {
                    var addressForValidation;
                    addressForValidation = this.selectedHospital.address + ', ' + this.selectedHospital.city + ', ' + this.selectedHospital.state + ' ' + this.selectedHospital.zip + ', ' + this.selectedHospital.country;
                    const geoLocation = await this.$store.dispatch(
                        'googleAPI/getGeoLocation',
                        addressForValidation,
                    );
                    if (!geoLocation) {
                        // this.selectedMedicalInstitution.addressLatitude = 0.01;
                        // this.selectedMedicalInstitution.addressLongitude = 0.01;
                        return false;
                    }
                    if (geoLocation.address === undefined || geoLocation.address == null) {
                        // this.selectedMedicalInstitution.addressLatitude = 0.01;
                        // this.selectedMedicalInstitution.addressLongitude = 0.01;
                        return false;
                    }

                    const addressComponents = geoLocation.address;

                    this.selectedHospital.addressLatitude = (geoLocation.lat === undefined || geoLocation.lat === null) ? 0.01 : geoLocation.lat;
                    this.selectedHospital.addressLongitude = (geoLocation.lon === undefined || geoLocation.lon === null) ? 0.01 : geoLocation.lon;
                    this.selectedHospital.address = (geoLocation.display_name === undefined || geoLocation.display_name === null) ? this.selectedHospital.address : geoLocation.display_name;
                    this.selectedHospital.city = addressComponents.city || addressComponents.village || addressComponents.suburb || 'N/A';
                    this.selectedHospital.state = addressComponents.state || 'N/A';
                    this.selectedHospital.country = addressComponents.country || 'N/A';
                    this.selectedHospital.zip = addressComponents.postcode || 'N/A';
                    return true;
                } else {
                    this.selectedHospital.addressLatitude = 0.01;
                    this.selectedHospital.addressLongitude = 0.01;
                    return false;
                }
            } catch (error) {
              this.selectedHospital.addressLatitude = 0.01;
              this.selectedHospital.addressLongitude = 0.01;
              return false;
            }
        },
    },
};
</script>
