<template>
    <div>
        <v-card v-if="selectedHospitalAdmin !== undefined && selectedHospitalAdmin !== null"
            class="mx-auto"
            elevation="0"
        >
            <v-card-title class="card-header text-left fixed-card-title">
                <v-row>
                    <v-col cols="10">
                        <v-toolbar-title >{{$t('adminsIconTooltip')}}</v-toolbar-title>
                    </v-col>
                  <v-spacer></v-spacer>
                  <v-icon @click="closeDrawer" class="white-icon mr-3">mdi-close</v-icon>
                </v-row>
            </v-card-title>
            <v-card-text class="px-2 pt-16">
                <v-form
                    ref="form"
                    v-model="valid"
                    lazy-validation
                >
                    <!-- Data -->
                    <v-container>
                        <v-row class="pt-2">
                            <v-col
                                class="d-flex pa-1"
                                cols="6"
                                sm="6"
                            >
                                <v-text-field
                                    outlined
                                    hide-details="auto"
                                    dense
                                    v-model="selectedHospitalAdmin.firstName"
                                    :rules="[v => !!v || 'Item is required']"
                                    :label="$t('first-name')"
                                    required>
                                </v-text-field>
                            </v-col>
                            <v-col
                                class="d-flex pa-1"
                                cols="6"
                                sm="6"
                            >
                                <v-text-field
                                    outlined
                                    hide-details="auto"
                                    dense
                                    v-model="selectedHospitalAdmin.lastName"
                                    :rules="[v => !!v || 'Item is required']"
                                    :label="$t('last-name')"
                                    required>
                                </v-text-field>
                            </v-col>
                        </v-row>
                      <v-row>
                        <v-col
                            class="d-flex pa-1"
                            cols="12"
                            sm="12"
                        >
                          <v-select
                              class="width-text-field2"
                              hide-details
                              dense
                              v-model="selectedHospitalAdmin.roleId"
                              :items="filteredRoles"
                              item-value="id"
                              item-text="roleNameForDisplay"
                              :label="$t('role')"
                              outlined
                              :menu-props="{ offsetY: true }"
                          >
                          </v-select>
                        </v-col>
                      </v-row>
                        <v-row>
                            <v-col
                                class="d-flex pa-1"
                                cols="12"
                                sm="12"
                            >
                                <v-select
                                    class="width-text-field2"
                                    hide-details
                                    dense
                                    v-model="selectedHospitalAdmin.hospitalAssignedData.medicalInstitutionId"
                                    :items="allMedicalInstitutions"
                                    item-value="id"
                                    item-text="institutionName"
                                    :label="$t('medicalInstitution')"
                                    @change="setSelectedHospitalOrOrganization"
                                    outlined
                                    :menu-props="{ offsetY: true }"
                                >
                                </v-select>
                            </v-col>
                        </v-row>
                        <v-row v-if="selectedHospitalAdmin.roleId === 6">
                            <v-col
                                class="d-flex pa-1"
                                cols="12"
                                sm="12"
                            >
                                <v-select
                                    class="width-text-field2"
                                    hide-details
                                    dense
                                    v-model="selectedHospitalAdmin.hospitalAssignedData.id"
                                    :items="filteredHospitals"
                                    item-value="id"
                                    item-text="hospitalName"
                                    :label="$t('hospital')"
                                    outlined
                                    @change="setSelectedHospitalOrOrganization"
                                    :menu-props="{ offsetY: true }"
                                >
                                </v-select>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col
                                class="d-flex pa-1"
                                cols="12"
                                sm="12"
                            >
                                <div class="pt-4">
                                    <h5 class="heading text-left">{{this.$t('address')}}</h5>
                                </div>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col
                                class="d-flex pa-1"
                                cols="12"
                                sm="12"
                            >
                                <v-text-field
                                    outlined
                                    hide-details="auto"
                                    dense
                                    v-model="selectedHospitalAdmin.address"
                                    :rules="[v => !!v || 'Item is required']"
                                    :label="$t('address')"
                                    required>
                                </v-text-field>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col
                                class="d-flex pa-1"
                                cols="6"
                                sm="6"
                            >
                                <v-text-field
                                    outlined
                                    hide-details="auto"
                                    dense
                                    v-model="selectedHospitalAdmin.city"
                                    :rules="[v => !!v || 'Item is required']"
                                    :label="$t('city')"
                                    required>
                                </v-text-field>
                            </v-col>
                            <v-col
                                class="d-flex pa-1"
                                cols="6"
                                sm="6"
                            >
                                <v-text-field
                                    outlined
                                    hide-details="auto"
                                    dense
                                    v-model="selectedHospitalAdmin.state"
                                    :rules="[v => !!v || 'Item is required']"
                                    :label="$t('state')"
                                    required>
                                </v-text-field>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col
                                class="d-flex pa-1"
                                cols="6"
                                sm="6"
                            >
                                <v-text-field
                                    outlined
                                    hide-details="auto"
                                    dense
                                    v-model="selectedHospitalAdmin.zip"
                                    :rules="[v => !!v || 'Item is required']"
                                    :label="$t('zip')"
                                    required>
                                </v-text-field>
                            </v-col>
                            <v-col
                                class="d-flex pa-1"
                                cols="6"
                                sm="6"
                            >
                                <v-text-field
                                    outlined
                                    hide-details="auto"
                                    dense
                                    v-model="selectedHospitalAdmin.country"
                                    :rules="[v => !!v || 'Item is required']"
                                    :label="$t('country')"
                                    required>
                                </v-text-field>
                            </v-col>
                        </v-row>
                        <v-row v-if="!addressIsValid">
                            <v-col class="pa-1">
                                <v-combobox
                                    :label="$t('time-zone')"
                                    dense
                                    hide-details="auto"
                                    outlined
                                    :rules="[v => !!v || $t('timeZoneSelect')]"
                                    v-model="timezone"
                                    item-text="timeZoneId"
                                    item-value="timeZoneId"
                                    :items="getTimeZone"
                                ></v-combobox>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col
                                class="d-flex pa-1"
                                cols="12"
                                sm="12"
                            >
                                <div class="pt-4">
                                    <h5 class="heading text-left">{{this.$t('contact')}}</h5>
                                </div>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col
                                class="d-flex pa-1"
                                cols="6"
                                sm="6"
                            >
                                <v-text-field
                                    outlined
                                    hide-details="auto"
                                    dense
                                    v-model="selectedHospitalAdmin.phone"
                                    @input="formatPhoneNumberForHospitalAdmin"
                                    :rules="[v => !!v || 'Item is required']"
                                    :label="$t('contact-phone')"
                                    required>
                                </v-text-field>
                            </v-col>
                            <v-col
                                class="d-flex pa-1"
                                cols="6"
                                sm="6"
                            >
                                <v-text-field
                                    outlined
                                    hide-details="auto"
                                    dense
                                    v-model="selectedHospitalAdmin.email"
                                    :rules="[v => !!v || 'Item is required']"
                                    :label="$t('contact-email')"
                                    required>
                                </v-text-field>
                            </v-col>
                        </v-row>
                    </v-container>
                </v-form>
            </v-card-text>
            <v-card-actions class="fixed-card-actions">
                <v-row class="mb-0">
                    <v-col cols="6">
                        <v-btn
                            @click="closeDrawer"
                            elevation="0"
                            class="cancel-button-style capitalize-text"
                            >
                        {{$t('cancel')}}
                        </v-btn>
                    </v-col>
                    <v-col cols="6">
                        <v-btn
                            :disabled="!valid"
                            @click="saveHospitalAdmin"
                            class="save-button-style"
                        >
                        {{ $t('save') }}
                        </v-btn>
                    </v-col>
                </v-row>
            </v-card-actions>
        </v-card>
        <v-card v-if="selectedOrganizationAdmin !== undefined && selectedOrganizationAdmin !== null"
            class="mx-auto"
            elevation="0"
        >
            <v-card-title class="card-header text-left fixed-card-title" >
                <v-row>
                    <v-col cols="10">
                        <v-toolbar-title >{{$t('edit-staff')}}</v-toolbar-title>
                    </v-col>
                </v-row>
            </v-card-title>
            <v-card-text class="px-2 pt-16">
                <v-form
                    ref="form"
                    v-model="valid"
                    lazy-validation
                >
                    <!-- Data -->
                    <v-container>
                        <v-row class="pt-2">
                            <v-col
                                class="d-flex pa-1"
                                cols="6"
                                sm="6"
                            >
                                <v-text-field
                                    outlined
                                    hide-details="auto"
                                    dense
                                    v-model="selectedOrganizationAdmin.firstName"
                                    :rules="[v => !!v || 'Item is required']"
                                    :label="$t('first-name')"
                                    required>
                                </v-text-field>
                            </v-col>
                            <v-col
                                class="d-flex pa-1"
                                cols="6"
                                sm="6"
                            >
                                <v-text-field
                                    outlined
                                    hide-details="auto"
                                    dense
                                    v-model="selectedOrganizationAdmin.lastName"
                                    :rules="[v => !!v || 'Item is required']"
                                    :label="$t('last-name')"
                                    required>
                                </v-text-field>
                            </v-col>
                        </v-row>
                      <v-row>
                        <v-col
                            class="d-flex pa-1"
                            cols="12"
                            sm="12"
                        >
                          <v-select
                              class="width-text-field2"
                              hide-details
                              dense
                              v-model="selectedOrganizationAdmin.roleId"
                              :items="filteredRoles"
                              item-value="id"
                              item-text="roleNameForDisplay"
                              :label="$t('role')"
                              outlined
                          >
                          </v-select>
                        </v-col>
                      </v-row>
                        <v-row>
                            <v-col
                                class="d-flex pa-1"
                                cols="12"
                                sm="12"
                            >
                                <v-select
                                    class="width-text-field2"
                                    hide-details
                                    dense
                                    v-model="selectedOrganizationAdmin.organizationId"
                                    :items="allMedicalInstitutions"
                                    item-value="id"
                                    item-text="institutionName"
                                    :label="$t('medicalInstitution')"
                                    outlined
                                    @change="setSelectedOrganization"
                                >
                                </v-select>
                            </v-col>
                        </v-row>
                        <v-row v-if="selectedOrganizationAdmin.roleId === 6">
                            <v-col
                                class="d-flex pa-1"
                                cols="12"
                                sm="12"
                            >
                                <v-select
                                    class="width-text-field2"
                                    hide-details
                                    dense
                                    v-model="selectedHospitalAdmin_HospitalId"
                                    :items="filteredHospitals"
                                    item-value="id"
                                    item-text="hospitalName"
                                    :label="$t('hospital')"
                                    outlined
                                    @change="setSelectedHospitalOrOrganization"
                                >
                                </v-select>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col
                                class="d-flex pa-1"
                                cols="12"
                                sm="12"
                            >
                                <div class="pt-4">
                                    <h5 class="heading text-left">{{this.$t('address')}}</h5>
                                </div>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col
                                class="d-flex pa-1"
                                cols="12"
                                sm="12"
                            >
                                <v-text-field
                                    outlined
                                    hide-details="auto"
                                    dense
                                    v-model="selectedOrganizationAdmin.address"
                                    :rules="[v => !!v || 'Item is required']"
                                    :label="$t('address')"
                                    required>
                                </v-text-field>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col
                                class="d-flex pa-1"
                                cols="6"
                                sm="6"
                            >
                                <v-text-field
                                    outlined
                                    hide-details="auto"
                                    dense
                                    v-model="selectedOrganizationAdmin.city"
                                    :rules="[v => !!v || 'Item is required']"
                                    :label="$t('city')"
                                    required>
                                </v-text-field>
                            </v-col>
                            <v-col
                                class="d-flex pa-1"
                                cols="6"
                                sm="6"
                            >
                                <v-text-field
                                    outlined
                                    hide-details="auto"
                                    dense
                                    v-model="selectedOrganizationAdmin.state"
                                    :rules="[v => !!v || 'Item is required']"
                                    :label="$t('state')"
                                    required>
                                </v-text-field>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col
                                class="d-flex pa-1"
                                cols="6"
                                sm="6"
                            >
                                <v-text-field
                                    outlined
                                    hide-details="auto"
                                    dense
                                    v-model="selectedOrganizationAdmin.zip"
                                    :rules="[v => !!v || 'Item is required']"
                                    :label="$t('zip')"
                                    required>
                                </v-text-field>
                            </v-col>
                            <v-col
                                class="d-flex pa-1"
                                cols="6"
                                sm="6"
                            >
                                <v-text-field
                                    outlined
                                    hide-details="auto"
                                    dense
                                    v-model="selectedOrganizationAdmin.country"
                                    :rules="[v => !!v || 'Item is required']"
                                    :label="$t('country')"
                                    required>
                                </v-text-field>
                            </v-col>
                        </v-row>
                      <v-row v-if="!addressIsValid">
                        <v-col class="pa-1">
                          <v-combobox
                              :label="$t('time-zone')"
                              dense
                              hide-details="auto"
                              outlined
                              :rules="[v => !!v || $t('timeZoneSelect')]"
                              v-model="timezone"
                              item-text="timeZoneId"
                              item-value="timeZoneId"
                              :items="getTimeZone"
                          ></v-combobox>
                        </v-col>
                      </v-row>
                        <v-row>
                            <v-col
                                class="d-flex pa-1"
                                cols="12"
                                sm="12"
                            >
                                <div class="pt-4">
                                    <h5 class="heading text-left">{{this.$t('contact')}}</h5>
                                </div>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col
                                class="d-flex pa-1"
                                cols="6"
                                sm="6"
                            >
                                <v-text-field
                                    outlined
                                    hide-details
                                    dense
                                    @input="formatPhoneNumberForOrgAdmin"
                                    v-model="selectedOrganizationAdmin.phone"
                                    :rules="[v => !!v || 'Item is required']"
                                    :label="$t('contact-phone')"
                                    required>
                                </v-text-field>
                            </v-col>
                            <v-col
                                class="d-flex pa-1"
                                cols="6"
                                sm="6"
                            >
                                <v-text-field
                                    outlined
                                    hide-details
                                    dense
                                    v-model="selectedOrganizationAdmin.email"
                                    :rules="[v => !!v || 'Item is required']"
                                    :label="$t('contact-email')"
                                    required>
                                </v-text-field>
                            </v-col>
                        </v-row>
                    </v-container>
                </v-form>
            </v-card-text>
            <v-card-actions class="fixed-card-actions">
                <v-row class="mb-0">
                    <v-col cols="6">
                        <v-btn
                            @click="closeDrawer"
                            elevation="0"
                            class="cancel-button-style capitalize-text"
                            >
                        {{$t('cancel')}}
                        </v-btn>
                    </v-col>
                    <v-col cols="6">
                        <v-btn
                            :disabled="!valid"
                            @click="saveOrganizationAdmin"
                            class="save-button-style"
                        >
                        {{ $t('save') }}
                        </v-btn>
                    </v-col>
                </v-row>
            </v-card-actions>
        </v-card>
        <v-card v-if="selectedCaregiver !== undefined && selectedCaregiver !== null"
            class="mx-auto"
            elevation="0"
        >
            <v-card-title class="card-header text-left" id="fixed-card-title">
                <v-row>
                    <v-col cols="10">
                        <v-toolbar-title >{{$t('edit-staff')}}</v-toolbar-title>
                    </v-col>
                  <v-spacer></v-spacer>
                  <v-icon @click="closeDrawer" class="white-icon mr-3">mdi-close</v-icon>
                </v-row>
            </v-card-title>
            <v-card-text class="px-2 py-16">
                <v-form
                    ref="form"
                    v-model="valid"
                    lazy-validation
                >
                    <!-- Data -->
                    <v-container>
                        <v-row class="pt-2">
                            <v-col
                                class="d-flex pa-1"
                                cols="6"
                                sm="6"
                            >
                                <v-text-field
                                    outlined
                                    hide-details="auto"
                                    dense
                                    v-model="selectedCaregiver.firstName"
                                    :rules="[v => !!v || 'Item is required']"
                                    :label="$t('first-name')"
                                    required>
                                </v-text-field>
                            </v-col>
                            <v-col
                                class="d-flex pa-1"
                                cols="6"
                                sm="6"
                            >
                                <v-text-field
                                    outlined
                                    hide-details="auto"
                                    dense
                                    v-model="selectedCaregiver.lastName"
                                    :rules="[v => !!v || 'Item is required']"
                                    :label="$t('last-name')"
                                    required>
                                </v-text-field>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col
                                class="d-flex pa-1"
                                cols="12"
                                sm="12"
                            >
                                <v-select
                                    class="width-text-field2"
                                    hide-details
                                    dense
                                    v-model="selectedCaregiver.hospitalAssignedData.medicalInstitutionId"
                                    :items="allMedicalInstitutions"
                                    item-value="id"
                                    item-text="institutionName"
                                    :label="$t('medicalInstitution')"
                                    @change="handleSelectChangeOrganization"
                                    outlined
                                >
                                </v-select>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col
                                class="d-flex pa-1"
                                cols="12"
                                sm="12"
                            >
                                <v-select
                                    class="width-text-field2"
                                    hide-details
                                    dense
                                    v-model="selectedCaregiver.hospitalAssignedData.id"
                                    :items="filteredHospitals"
                                    item-value="id"
                                    item-text="hospitalName"
                                    :label="$t('hospital')"
                                    outlined
                                >
                                </v-select>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col
                                class="d-flex pa-1"
                                cols="12"
                                sm="12"
                            >
                                <v-select
                                    class="width-text-field2"
                                    hide-details
                                    dense
                                    v-model="selectedCaregiver.roleId"
                                    :items="filteredRoles"
                                    item-value="id"
                                    item-text="roleNameForDisplay"
                                    :label="$t('role')"
                                    outlined
                                >
                                </v-select>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col
                                class="d-flex pa-1"
                                cols="12"
                                sm="12"
                            >
                                <div class="pt-4">
                                    <h5 class="heading text-left">{{this.$t('address')}}</h5>
                                </div>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col
                                class="d-flex pa-1"
                                cols="12"
                                sm="12"
                            >
                                <v-text-field
                                    outlined
                                    hide-details="auto"
                                    dense
                                    v-model="selectedCaregiver.address"
                                    :rules="[v => !!v || 'Item is required']"
                                    :label="$t('address')"
                                    required>
                                </v-text-field>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col
                                class="d-flex pa-1"
                                cols="6"
                                sm="6"
                            >
                                <v-text-field
                                    outlined
                                    hide-details="auto"
                                    dense
                                    v-model="selectedCaregiver.city"
                                    :rules="[v => !!v || 'Item is required']"
                                    :label="$t('city')"
                                    required>
                                </v-text-field>
                            </v-col>
                            <v-col
                                class="d-flex pa-1"
                                cols="6"
                                sm="6"
                            >
                                <v-text-field
                                    outlined
                                    hide-details="auto"
                                    dense
                                    v-model="selectedCaregiver.state"
                                    :rules="[v => !!v || 'Item is required']"
                                    :label="$t('state')"
                                    required>
                                </v-text-field>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col
                                class="d-flex pa-1"
                                cols="6"
                                sm="6"
                            >
                                <v-text-field
                                    outlined
                                    hide-details="auto"
                                    dense
                                    v-model="selectedCaregiver.zip"
                                    :rules="[v => !!v || 'Item is required']"
                                    :label="$t('zip')"
                                    required>
                                </v-text-field>
                            </v-col>
                            <v-col
                                class="d-flex pa-1"
                                cols="6"
                                sm="6"
                            >
                                <v-text-field
                                    outlined
                                    hide-details="auto"
                                    dense
                                    v-model="selectedCaregiver.country"
                                    :rules="[v => !!v || 'Item is required']"
                                    :label="$t('country')"
                                    required>
                                </v-text-field>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col
                                class="d-flex pa-1"
                                cols="12"
                                sm="12"
                            >
                                <div class="pt-4">
                                    <h5 class="heading text-left">{{this.$t('contact')}}</h5>
                                </div>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col
                                class="d-flex pa-1"
                                cols="6"
                                sm="6"
                            >
                                <v-text-field
                                    outlined
                                    hide-details="auto"
                                    dense
                                    v-model="selectedCaregiver.phone"
                                    :rules="[v => !!v || 'Item is required']"
                                    :label="$t('contact-phone')"
                                    required>
                                </v-text-field>
                            </v-col>
                            <v-col
                                class="d-flex pa-1"
                                cols="6"
                                sm="6"
                            >
                                <v-text-field
                                    outlined
                                    hide-details="auto"
                                    dense
                                    v-model="selectedCaregiver.email"
                                    :rules="[v => !!v || 'Item is required']"
                                    :label="$t('contact-email')"
                                    required>
                                </v-text-field>
                            </v-col>
                        </v-row>
                    </v-container>
                </v-form>
            </v-card-text>
            <v-card-actions class="fixed-card-actions">
                <v-row class="mb-0">
                    <v-col cols="6">
                        <v-btn
                            @click="closeDrawer"
                            elevation="0"
                            class="cancel-button-style capitalize-text"
                            >
                        {{$t('cancel')}}
                        </v-btn>
                    </v-col>
                    <v-col cols="6">
                        <v-btn
                            :disabled="!valid"
                            @click="saveCaregiver"
                            class="save-button-style"
                        >
                        {{ $t('save') }}
                        </v-btn>
                    </v-col>
                </v-row>
            </v-card-actions>
        </v-card>
        <v-card v-if="selectedPartner !== undefined && selectedPartner !== null"
            class="mx-auto"
            elevation="0"
        >
            <v-card-title class="card-header text-left" id="fixed-card-title">
                <v-row>
                    <v-col cols="10">
                        <v-toolbar-title >{{$t('edit-staff')}}</v-toolbar-title>
                    </v-col>
                  <v-spacer></v-spacer>
                  <v-icon @click="closeDrawer" class="white-icon mr-3">mdi-close</v-icon>
                </v-row>
            </v-card-title>
            <v-card-text class="px-2 my-1">
                <v-form
                    ref="form"
                    v-model="valid"
                    lazy-validation
                >
                    <!-- Data -->
                    <v-container>
                        <v-row class="pt-2">
                            <v-col
                                class="d-flex pa-1"
                                cols="6"
                                sm="6"
                            >
                                <v-text-field
                                    outlined
                                    hide-details="auto"
                                    dense
                                    v-model="selectedPartner.firstName"
                                    :rules="[v => !!v || 'Item is required']"
                                    :label="$t('first-name')"
                                    required>
                                </v-text-field>
                            </v-col>
                            <v-col
                                class="d-flex pa-1"
                                cols="6"
                                sm="6"
                            >
                                <v-text-field
                                    outlined
                                    hide-details="auto"
                                    dense
                                    v-model="selectedPartner.lastName"
                                    :rules="[v => !!v || 'Item is required']"
                                    :label="$t('last-name')"
                                    required>
                                </v-text-field>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col
                                class="d-flex pa-1"
                                cols="12"
                                sm="12"
                            >
                                <div class="pt-4">
                                    <h5 class="heading text-left">{{this.$t('address')}}</h5>
                                </div>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col
                                class="d-flex pa-1"
                                cols="12"
                                sm="12"
                            >
                                <v-text-field
                                    outlined
                                    hide-details="auto"
                                    dense
                                    v-model="selectedPartner.address"
                                    :rules="[v => !!v || 'Item is required']"
                                    :label="$t('address')"
                                    required>
                                </v-text-field>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col
                                class="d-flex pa-1"
                                cols="6"
                                sm="6"
                            >
                                <v-text-field
                                    outlined
                                    hide-details="auto"
                                    dense
                                    v-model="selectedPartner.city"
                                    :rules="[v => !!v || 'Item is required']"
                                    :label="$t('city')"
                                    required>
                                </v-text-field>
                            </v-col>
                            <v-col
                                class="d-flex pa-1"
                                cols="6"
                                sm="6"
                            >
                                <v-text-field
                                    outlined
                                    hide-details="auto"
                                    dense
                                    v-model="selectedPartner.state"
                                    :rules="[v => !!v || 'Item is required']"
                                    :label="$t('state')"
                                    required>
                                </v-text-field>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col
                                class="d-flex pa-1"
                                cols="6"
                                sm="6"
                            >
                                <v-text-field
                                    outlined
                                    hide-details="auto"
                                    dense
                                    v-model="selectedPartner.zip"
                                    :rules="[v => !!v || 'Item is required']"
                                    :label="$t('zip')"
                                    required>
                                </v-text-field>
                            </v-col>
                            <v-col
                                class="d-flex pa-1"
                                cols="6"
                                sm="6"
                            >
                                <v-text-field
                                    outlined
                                    hide-details="auto"
                                    dense
                                    v-model="selectedPartner.country"
                                    :rules="[v => !!v || 'Item is required']"
                                    :label="$t('country')"
                                    required>
                                </v-text-field>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col
                                class="d-flex pa-1"
                                cols="12"
                                sm="12"
                            >
                                <div class="pt-4">
                                    <h5 class="heading text-left">{{this.$t('contact')}}</h5>
                                </div>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col
                                class="d-flex pa-1"
                                cols="6"
                                sm="6"
                            >
                                <v-text-field
                                    outlined
                                    hide-details="auto"
                                    dense
                                    v-model="selectedPartner.phone"
                                    :rules="[v => !!v || 'Item is required']"
                                    :label="$t('contact-phone')"
                                    required>
                                </v-text-field>
                            </v-col>
                            <v-col
                                class="d-flex pa-1"
                                cols="6"
                                sm="6"
                            >
                                <v-text-field
                                    outlined
                                    hide-details="auto"
                                    dense
                                    v-model="selectedPartner.email"
                                    :rules="[v => !!v || 'Item is required']"
                                    :label="$t('contact-email')"
                                    required>
                                </v-text-field>
                            </v-col>
                        </v-row>
                    </v-container>
                </v-form>
            </v-card-text>
            <v-card-actions class="fixed-card-actions">
                <v-row class="mb-0">
                    <v-col cols="6">
                        <v-btn
                            @click="closeDrawer"
                            elevation="0"
                            class="cancel-button-style capitalize-text"
                            >
                        {{$t('cancel')}}
                        </v-btn>
                    </v-col>
                    <v-col cols="6">
                        <v-btn
                            :disabled="!valid"
                            @click="savePartner"
                            class="save-button-style"
                        >
                        {{ $t('save') }}
                        </v-btn>
                    </v-col>
                </v-row>
            </v-card-actions>
        </v-card>

      <v-card v-if="selectApiUser !== undefined && selectApiUser !== null"
              class="mx-auto"
              elevation="0"
      >
        <v-card-title class="card-header text-left" id="fixed-card-title">
          <v-row>
            <v-col cols="10">
              <v-toolbar-title >{{$t('edit-user')}}</v-toolbar-title>
            </v-col>
            <v-spacer></v-spacer>
            <v-icon @click="closeDrawer" class="white-icon mr-3">mdi-close</v-icon>
          </v-row>
        </v-card-title>
        <v-card-text class="px-2 my-1">
          <v-form
              ref="form"
              v-model="valid"
              lazy-validation
          >
            <!-- Data -->
            <v-container>
              <v-row class="pt-2">
                <v-col
                    class="d-flex pa-1"
                    cols="6"
                    sm="6"
                >
                  <v-text-field
                      outlined
                      hide-details="auto"
                      dense
                      v-model="selectApiUser.firstName"
                      :rules="[v => !!v || 'Item is required']"
                      :label="$t('first-name')"
                      required>
                  </v-text-field>
                </v-col>
                <v-col
                    class="d-flex pa-1"
                    cols="6"
                    sm="6"
                >
                  <v-text-field
                      outlined
                      hide-details="auto"
                      dense
                      v-model="selectApiUser.lastName"
                      :rules="[v => !!v || 'Item is required']"
                      :label="$t('last-name')"
                      required>
                  </v-text-field>
                </v-col>
              </v-row>
              <v-row>
                <v-col
                    class="d-flex pa-1"
                    cols="12"
                    sm="12"
                >
                  <div class="pt-4">
                    <h5 class="heading text-left">{{this.$t('address')}}</h5>
                  </div>
                </v-col>
              </v-row>
              <v-row>
                <v-col
                    class="d-flex pa-1"
                    cols="12"
                    sm="12"
                >
                  <v-text-field
                      outlined
                      hide-details="auto"
                      dense
                      v-model="selectApiUser.address"
                      :rules="[v => !!v || 'Item is required']"
                      :label="$t('address')"
                      required>
                  </v-text-field>
                </v-col>
              </v-row>
              <v-row>
                <v-col
                    class="d-flex pa-1"
                    cols="6"
                    sm="6"
                >
                  <v-text-field
                      outlined
                      hide-details="auto"
                      dense
                      v-model="selectApiUser.city"
                      :rules="[v => !!v || 'Item is required']"
                      :label="$t('city')"
                      required>
                  </v-text-field>
                </v-col>
                <v-col
                    class="d-flex pa-1"
                    cols="6"
                    sm="6"
                >
                  <v-text-field
                      outlined
                      hide-details="auto"
                      dense
                      v-model="selectApiUser.state"
                      :rules="[v => !!v || 'Item is required']"
                      :label="$t('state')"
                      required>
                  </v-text-field>
                </v-col>
              </v-row>
              <v-row>
                <v-col
                    class="d-flex pa-1"
                    cols="6"
                    sm="6"
                >
                  <v-text-field
                      outlined
                      hide-details="auto"
                      dense
                      v-model="selectApiUser.zip"
                      :rules="[v => !!v || 'Item is required']"
                      :label="$t('zip')"
                      required>
                  </v-text-field>
                </v-col>
                <v-col
                    class="d-flex pa-1"
                    cols="6"
                    sm="6"
                >
                  <v-text-field
                      outlined
                      hide-details="auto"
                      dense
                      v-model="selectApiUser.country"
                      :rules="[v => !!v || 'Item is required']"
                      :label="$t('country')"
                      required>
                  </v-text-field>
                </v-col>
              </v-row>
              <v-row>
                <v-col
                    class="d-flex pa-1"
                    cols="12"
                    sm="12"
                >
                  <div class="pt-4">
                    <h5 class="heading text-left">{{this.$t('contact')}}</h5>
                  </div>
                </v-col>
              </v-row>
              <v-row>
                <v-col
                    class="d-flex pa-1"
                    cols="6"
                    sm="6"
                >
                  <v-text-field
                      outlined
                      hide-details="auto"
                      dense
                      v-model="selectApiUser.phone"
                      :rules="[v => !!v || 'Item is required']"
                      :label="$t('contact-phone')"
                      required>
                  </v-text-field>
                </v-col>
                <v-col
                    class="d-flex pa-1"
                    cols="6"
                    sm="6"
                >
                  <v-text-field
                      outlined
                      hide-details="auto"
                      dense
                      v-model="selectApiUser.email"
                      :rules="[v => !!v || 'Item is required']"
                      :label="$t('contact-email')"
                      required>
                  </v-text-field>
                </v-col>
              </v-row>
            </v-container>
          </v-form>
        </v-card-text>
        <v-card-actions class="fixed-card-actions">
          <v-row class="mb-0">
            <v-col cols="6">
              <v-btn
                  @click="closeDrawer"
                  elevation="0"
                  class="cancel-button-style capitalize-text"
              >
                {{$t('cancel')}}
              </v-btn>
            </v-col>
            <v-col cols="6">
              <v-btn
                  :disabled="!valid"
                  @click="saveApiUser"
                  class="save-button-style"
              >
                {{ $t('save') }}
              </v-btn>
            </v-col>
          </v-row>
        </v-card-actions>
      </v-card>
    </div>
</template>

<script>
import { closeWhite } from '@/assets/index';
import { mapGetters, mapState } from 'vuex';
import { showSuccessAlert, showErrorAlert } from '@/utils/utilities';
import { AsYouType } from 'libphonenumber-js';

export default {
  name: 'HospitalEditDrawerDialog',
  components: {},
  data: () => ({
      closeWhite: closeWhite,
      valid: true,
      selectedHospitalOrOrganization: null,
      menu: false,
      timezone: null,
      addressIsValid: true,
      selectedHospitalAdmin_HospitalId: -1,
  }),
  async mounted () {
    await this.getTimeZones();
    await this.getAllMedicalInstitutions();
    await this.getAllHospitals();
    if (this.selectedHospitalAdmin !== undefined && this.selectedHospitalAdmin !== null) {
      if (this.selectedHospitalAdmin.roleId === 6) {
        this.setSelectedHospitalOrOrganization(this.selectedHospitalAdmin.hospitalAssignedData.id);
        this.timezone = this.selectedHospitalAdmin.userTimezone;
      }
      return;
    }
    if (this.selectedOrganizationAdmin !== undefined && this.selectedOrganizationAdmin !== null) {
      if (this.selectedOrganizationAdmin.roleId === 8) {
        this.setSelectedOrganization(this.selectedOrganizationAdmin.organizationId);
        this.timezone = this.selectedOrganizationAdmin.userTimezone;
      }
    }
  },
  watch: {
    updateDrawer: {
      handler (val) {
        if (val) {
        if (this.selectedHospitalAdmin !== undefined && this.selectedHospitalAdmin !== null) {
          if (this.selectedHospitalAdmin.roleId === 6) {
            this.setSelectedHospitalOrOrganization(this.selectedHospitalAdmin.hospitalAssignedData.id);
            this.timezone = this.selectedHospitalAdmin.userTimezone;
          }
          return;
        }
        if (this.selectedOrganizationAdmin !== undefined && this.selectedOrganizationAdmin !== null) {
          if (this.selectedOrganizationAdmin.roleId === 8) {
            this.setSelectedOrganization(this.selectedOrganizationAdmin.organizationId);
            this.timezone = this.selectedOrganizationAdmin.userTimezone;
          }
        }
        }
      },
      deep: true,
    },
  },
  computed: {
    ...mapGetters({
      delay: 'authentication/getDelay',
      appBarSearch: 'filterbar/getSearch',
      appBarMedicalInstitution: 'filterbar/getMedicalInstitution',
      getTimeZone: 'verifiers/getTimeZone',
      roleName: 'authentication/getRole',
    }),
    ...mapState({
      updateDrawer: (state) => state.updateDrawer,
        allMedicalInstitutions: (state) => state.medicalInstitutions.medicalInstitutions,
        hospitals: (state) => state.hospitals.hospitals,
        selectedOrganizationAdmin: (state) => state.users.selectedOrganizationAdmin,
        selectedHospitalAdmin: (state) => state.users.selectedHospitalAdmin,
        selectedPartner: (state) => state.users.selectedPartner,
        selectedCaregiver: (state) => state.users.selectedCaregiver,
        selectApiUser: (state) => state.users.selectedApiUser,
        allRoles: (state) => state.roles.roles,
        authenticated: state => state.authentication.userData,
    }),
    filteredHospitals () {
        if (this.hospitals == null) {
            return;
        }

        let calculateFileteredHospital = this.hospitals;

        if (this.selectedOrganizationAdmin !== null && this.selectedOrganizationAdmin !== undefined) {
            if (this.selectedOrganizationAdmin.organizationId !== null && this.selectedOrganizationAdmin.organizationId !== 0) {
                calculateFileteredHospital = calculateFileteredHospital.filter(h => h.medicalInstitutionId === this.selectedOrganizationAdmin.organizationId);
            } else {
                calculateFileteredHospital = this.hospitals;
            }
        }

        if (this.selectedHospitalAdmin !== null && this.selectedHospitalAdmin !== undefined) {
            if (this.selectedHospitalAdmin.hospitalAssignedData.medicalInstitutionId !== null && this.selectedHospitalAdmin.hospitalAssignedData.medicalInstitutionId !== 0) {
                calculateFileteredHospital = calculateFileteredHospital.filter(h => h.medicalInstitutionId === this.selectedHospitalAdmin.hospitalAssignedData.medicalInstitutionId);
            } else {
                calculateFileteredHospital = this.hospitals;
            }
        }

        if (this.selectedCaregiver !== null && this.selectedCaregiver !== undefined) {
            if (this.selectedCaregiver.hospitalAssignedData.medicalInstitutionId !== null && this.selectedCaregiver.hospitalAssignedData.medicalInstitutionId !== 0) {
                calculateFileteredHospital = calculateFileteredHospital.filter(h => h.medicalInstitutionId === this.selectedCaregiver.hospitalAssignedData.medicalInstitutionId);
            } else {
                calculateFileteredHospital = this.hospitals;
            }
        }

        return calculateFileteredHospital;
    },
    filteredRoles () {
        // const fileteredRolesReturn = this.allRoles.filter(r => r.roleName === 'HOSPITAL_ADMIN' || r.roleName === 'ORGANIZATION_ADMIN' || r.roleName === 'CARE_GIVER');
        const fileteredRolesReturn = this.allRoles.filter(r => r.roleName === 'HOSPITAL_ADMIN' || r.roleName === 'ORGANIZATION_ADMIN');
        return fileteredRolesReturn;
    },
  },
  methods: {
    async getPartners () {
      const organizationId = this.roleName === 'SYSTEM_ADMIN' ? -1 : this.authenticated.organizationId;
      const body = {
        organizationId: organizationId,
      };

      await this.$store.dispatch('users/getPartners', body);
    },
   async getTimeZones () {
      await this.$store.dispatch('verifiers/getTimeZones').then(() => {
      });
    },
    setSelectedHospitalOrOrganization (val) {
      if (this.selectedHospitalAdmin.roleId === 6) {
        this.selectedHospitalOrOrganization = this.hospitals.find(hospital => hospital.id === val);
        // eslint-disable-next-line no-empty
        if (this.selectedHospitalOrOrganization !== undefined && this.selectedHospitalOrOrganization !== null) {
        } else {
          return;
        }
      } else {
        this.selectedHospitalOrOrganization = this.allMedicalInstitutions.find(org => org.id === val);
      }

      this.selectedHospitalAdmin.address = this.selectedHospitalOrOrganization.address;
      this.selectedHospitalAdmin.city = this.selectedHospitalOrOrganization.city;
      this.selectedHospitalAdmin.state = this.selectedHospitalOrOrganization.state;
      this.selectedHospitalAdmin.zip = this.selectedHospitalOrOrganization.zip;
      this.selectedHospitalAdmin.country = this.selectedHospitalOrOrganization.country;
      if (this.selectedHospitalOrOrganization.addressLatitude !== 0.01 && this.selectedHospitalOrOrganization.addressLongitude !== 0.01 && this.selectedHospitalOrOrganization.addressLongitude !== null && this.selectedHospitalOrOrganization.addressLatitude !== null) {
        // TODO there is good address call  edpoint for geting the correct time zone with given lan lng

        this.addressIsValid = true;

        const latLng = {
          lat: this.selectedHospitalOrOrganization.addressLatitude,
          lng: this.selectedHospitalOrOrganization.addressLongitude,
        };

        this.$store.dispatch(
            'googleAPI/getTimeZone',
            latLng,
        ).then(response => {
          this.timezone = response.zoneName;
        });
      } else {
        // TODO There is bad address show time zone lookup
        this.addressIsValid = false;
        this.selectedHospitalOrOrganization.addressLatitude = 0.01;
        this.selectedHospitalOrOrganization.addressLongitude = 0.01;
      }
    },
    setSelectedOrganization (val) {
        this.selectedHospitalOrOrganization = this.allMedicalInstitutions.find(org => org.id === val);
      this.selectedOrganizationAdmin.address = this.selectedHospitalOrOrganization.address;
      this.selectedOrganizationAdmin.city = this.selectedHospitalOrOrganization.city;
      this.selectedOrganizationAdmin.state = this.selectedHospitalOrOrganization.state;
      this.selectedOrganizationAdmin.zip = this.selectedHospitalOrOrganization.zip;
      this.selectedOrganizationAdmin.country = this.selectedHospitalOrOrganization.country;
      if (this.selectedHospitalOrOrganization.addressLatitude !== 0.01 && this.selectedHospitalOrOrganization.addressLongitude !== 0.01 && this.selectedHospitalOrOrganization.addressLongitude !== null && this.selectedHospitalOrOrganization.addressLatitude !== null) {
        // TODO there is good address call  edpoint for geting the correct time zone with given lan lng

        this.addressIsValid = true;

        const latLng = {
          lat: this.selectedHospitalOrOrganization.addressLatitude,
          lng: this.selectedHospitalOrOrganization.addressLongitude,
        };

        this.$store.dispatch(
            'googleAPI/getTimeZone',
            latLng,
        ).then(response => {
          this.timezone = response.zoneName;
        });
      } else {
        // TODO There is bad address show time zoene lookup
        this.addressIsValid = false;
        this.selectedHospitalOrOrganization.addressLatitude = 0.01;
        this.selectedHospitalOrOrganization.addressLongitude = 0.01;
      }
    },
    formatPhoneNumberForHospitalAdmin (val) {
      this.selectedHospitalAdmin.phone = new AsYouType().input(val);
    },
    formatPhoneNumberForOrgAdmin (val) {
      this.selectedOrganizationAdmin.phone = new AsYouType().input(val);
    },
    async getOrganizationAmdinUsers () {
        await this.$store.dispatch('users/getOrganizationAdmins').then(() => {
            this.closeDrawer();
        });
    },
    async getHospitalAmdinsUsers () {
        await this.$store.dispatch('users/getHospitalAdmins').then(() => {
            this.closeDrawer();
        });
    },
    async getCaregivers () {
        await this.$store.dispatch('users/getCaregivers').then(() => {
            this.closeDrawer();
        });
    },
    async getAllMedicalInstitutions () {
        // we call this API endpoint only if the store values are null
        if (this.allMedicalInstitutions == null) {
            await this.$store.dispatch('medicalInstitutions/getMedicalInstitutions').then(() => {
                // this.loading = false;
            });
        }
    },
    async getAllHospitals () {
        // we call this API endpoint only if the store values are null
        if (this.hospitals == null) {
            await this.$store.dispatch('hospitals/getHospitals').then(() => {
                // this.loading = false;
            });
        }
    },
    handleSelectChangeOrganization (selectedOrganization) {
      // the change is reflected on computed property 'filteredHospitals'
    },
    async saveApiUser () {
      const waitAddressValidation = await this.checkAndFixLocationDetailsForPartner();
      if (!waitAddressValidation) {
        // this.validationErrors.push('address');
        // go on with the given addres data
      }

      this.selectApiUser.phone = new AsYouType().input(this.selectApiUser.phone); // TODO: this we need to do ONLY on presentation, NOT when saving on DB

      if (this.$refs.form.validate()) {
        const body = {
          id: this.selectApiUser.id,
          firstName: this.selectApiUser.firstName,
          lastName: this.selectApiUser.lastName,
          phone: this.selectApiUser.phone,
          email: this.selectApiUser.email,
          address: this.selectApiUser.address,
          zip: this.selectApiUser.zip,
          city: this.selectApiUser.city,
          state: this.selectApiUser.state,
          country: this.selectApiUser.country,
          addressLatitude: this.selectApiUser.addressLatitude,
          addressLongitude: this.selectApiUser.addressLongitude,
          // username: this.selectedHospitalAdmin.email,
          // status: 1, // this.hospitalAdminToAdd.status,
          // gender: this.hospitalAdminToAdd.gender,
          // birthdate: this.hospitalAdminToAdd.birthdate,
          roleId: this.selectApiUser.roleId, // 6, // HOSPITAL_ADMIN_ROLE
          userTimezone: this.addressIsValid ? this.timezone : this.timezone.timeZoneId,
          // userSettingsJson: this.hospitalAdminToAdd.userSettingsJson,
          // language: this.hospitalAdminToAdd.language,
         // hospitalId: this.selectedHospitalAdmin.hospitalAssignedData.id,
          organizationId: this.selectApiUser.organizationId,
        };
        await this.$store.dispatch('users/updateUser', body)
            .then((res) => {
              if (res) {
                showSuccessAlert(this.$t('success-operation'));
                this.getPartners();
                this.closeDrawer();
              } else {
                showErrorAlert(this.$t('failed-operation'));
              }
            });
      } else {
        this.$refs.form.validate();
      }
    },
    async savePartner () {
      const waitAddressValidation = await this.checkAndFixLocationDetailsForPartner();
      if (!waitAddressValidation) {
        // this.validationErrors.push('address');
        // go on with the given addres data
      }

      this.selectedPartner.phone = new AsYouType().input(this.selectedPartner.phone); // TODO: this we need to do ONLY on presentation, NOT when saving on DB

      if (this.$refs.form.validate()) {
        const body = {
          id: this.selectedPartner.id,
          firstName: this.selectedPartner.firstName,
          lastName: this.selectedPartner.lastName,
          phone: this.selectedPartner.phone,
          email: this.selectedPartner.email,
          address: this.selectedPartner.address,
          zip: this.selectedPartner.zip,
          city: this.selectedPartner.city,
          state: this.selectedPartner.state,
          country: this.selectedPartner.country,
          addressLatitude: this.selectedPartner.addressLatitude,
          addressLongitude: this.selectedPartner.addressLongitude,
          // username: this.selectedHospitalAdmin.email,
          // status: 1, // this.hospitalAdminToAdd.status,
          // gender: this.hospitalAdminToAdd.gender,
          // birthdate: this.hospitalAdminToAdd.birthdate,
          roleId: this.selectedPartner.roleId, // 6, // HOSPITAL_ADMIN_ROLE
          userTimezone: this.addressIsValid ? this.timezone : this.timezone.timeZoneId,
          // userSettingsJson: this.hospitalAdminToAdd.userSettingsJson,
          // language: this.hospitalAdminToAdd.language,
          // hospitalId: this.selectedHospitalAdmin.hospitalAssignedData.id,
          organizationId: this.selectedPartner.organizationId,
        };
        await this.$store.dispatch('users/updateUser', body)
            .then((res) => {
              if (res) {
                showSuccessAlert(this.$t('success-operation'));
                this.getPartners();
                this.closeDrawer();
              } else {
                showErrorAlert(this.$t('failed-operation'));
              }
            });
      } else {
        this.$refs.form.validate();
      }
    },
    async saveHospitalAdmin () {
      const waitAddressValidation = await this.checkAndFixLocationDetails();
      if (!waitAddressValidation) {
          // this.validationErrors.push('address');
          // go on with the given addres data
      }

      this.selectedHospitalAdmin.phone = new AsYouType().input(this.selectedHospitalAdmin.phone); // TODO: this we need to do ONLY on presentation, NOT when saving on DB

      if (this.$refs.form.validate()) {
        const body = {
          id: this.selectedHospitalAdmin.id,
          firstName: this.selectedHospitalAdmin.firstName,
          lastName: this.selectedHospitalAdmin.lastName,
          phone: this.selectedHospitalAdmin.phone,
          email: this.selectedHospitalAdmin.email,
          address: this.selectedHospitalAdmin.address,
          zip: this.selectedHospitalAdmin.zip,
          city: this.selectedHospitalAdmin.city,
          state: this.selectedHospitalAdmin.state,
          country: this.selectedHospitalAdmin.country,
          addressLatitude: this.selectedHospitalOrOrganization.addressLatitude,
          addressLongitude: this.selectedHospitalOrOrganization.addressLongitude,
          username: this.selectedHospitalAdmin.email,
          // status: 1, // this.hospitalAdminToAdd.status,
          // gender: this.hospitalAdminToAdd.gender,
          // birthdate: this.hospitalAdminToAdd.birthdate,
          roleId: this.selectedHospitalAdmin.roleId, // 6, // HOSPITAL_ADMIN_ROLE
          userTimezone: this.addressIsValid ? this.timezone : this.timezone.timeZoneId,
          // userSettingsJson: this.hospitalAdminToAdd.userSettingsJson,
          // language: this.hospitalAdminToAdd.language,
          hospitalId: this.selectedHospitalAdmin.hospitalAssignedData.id,
          organizationId: this.selectedHospitalAdmin.hospitalAssignedData.medicalInstitutionId,
        };
        await this.$store.dispatch('users/updateUser', body)
          .then((res) => {
            if (res) {
              showSuccessAlert(this.$t('success-operation'));
            //   this.getOrganizationAmdinUsers(); // reload the list of organization admins
            //   this.getHospitalAmdinsUsers(); // reload the list of hospital admins

              if (this.selectedHospitalAdmin.roleId === 6) {
                this.getHospitalAmdinsUsers(); // reload the list of hospital admins
              } else if (this.selectedHospitalAdmin.roleId === 8) {
                this.getOrganizationAmdinUsers(); // reload the list of organization admins
              }
            } else {
                showErrorAlert(this.$t('failed-operation'));
            }
          });
      } else {
        this.$refs.form.validate();
      }
    },
    async saveOrganizationAdmin () {
        const waitAddressValidation = await this.checkAndFixLocationDetailsForOrganizationAdmin();
        if (!waitAddressValidation) {
            // this.validationErrors.push('address');
            // go on with the given addres data
        }

        this.selectedOrganizationAdmin.phone = new AsYouType().input(this.selectedOrganizationAdmin.phone); // TODO: this we need to do ONLY on presentation, NOT when saving on DB

        if (this.$refs.form.validate()) {
            const body = {
            id: this.selectedOrganizationAdmin.id,
            firstName: this.selectedOrganizationAdmin.firstName,
            lastName: this.selectedOrganizationAdmin.lastName,
            phone: this.selectedOrganizationAdmin.phone,
            email: this.selectedOrganizationAdmin.email,
            address: this.selectedOrganizationAdmin.address,
            zip: this.selectedOrganizationAdmin.zip,
            city: this.selectedOrganizationAdmin.city,
            state: this.selectedOrganizationAdmin.state,
            country: this.selectedOrganizationAdmin.country,
            addressLatitude: this.selectedHospitalOrOrganization.addressLatitude,
            addressLongitude: this.selectedHospitalOrOrganization.addressLongitude,
            username: this.selectedOrganizationAdmin.email,
            // status: 1, // this.hospitalAdminToAdd.status,
            // gender: this.hospitalAdminToAdd.gender,
            // birthdate: this.hospitalAdminToAdd.birthdate,
            roleId: this.selectedOrganizationAdmin.roleId, // 8, // ORGANIZATION_ADMIN role
            userTimezone: this.addressIsValid ? this.timezone : this.timezone.timeZoneId,
            // userSettingsJson: this.hospitalAdminToAdd.userSettingsJson,
            // language: this.hospitalAdminToAdd.language,
            hospitalId: this.selectedHospitalAdmin_HospitalId,
            organizationId: this.selectedOrganizationAdmin.organizationId,
            };

            await this.$store.dispatch('users/updateUser', body)
            .then((res) => {
                if (res) {
                    showSuccessAlert(this.$t('success-operation'));
                    // this.getOrganizationAmdinUsers(); // reload the list of organization admins
                    // this.getHospitalAmdinsUsers(); // reload the list of hospital admins

                    if (this.selectedOrganizationAdmin.roleId === 6) {
                        this.getHospitalAmdinsUsers(); // reload the list of hospital admins
                    } else if (this.selectedOrganizationAdmin.roleId === 8) {
                        this.getOrganizationAmdinUsers(); // reload the list of organization admins
                    }
                } else {
                    showErrorAlert(this.$t('failed-operation'));
                }
            });
        } else {
            this.$refs.form.validate();
        }
    },
    async saveCaregiver () {
      const waitAddressValidation = await this.checkAndFixLocationDetails();
      if (!waitAddressValidation) {
          // this.validationErrors.push('address');
          // go on with the given addres data
      }

      this.selectedCaregiver.phone = new AsYouType().input(this.selectedCaregiver.phone); // TODO: this we need to do ONLY on presentation, NOT when saving on DB

      if (this.$refs.form.validate()) {
        const body = {
          id: this.selectedCaregiver.id,
          firstName: this.selectedCaregiver.firstName,
          lastName: this.selectedCaregiver.lastName,
          phone: this.selectedCaregiver.phone,
          email: this.selectedCaregiver.email,
          address: this.selectedCaregiver.address,
          zip: this.selectedCaregiver.zip,
          city: this.selectedCaregiver.city,
          state: this.selectedCaregiver.state,
          country: this.selectedCaregiver.country,
          addressLatitude: this.selectedCaregiver.addressLatitude,
          addressLongitude: this.selectedCaregiver.addressLongitude,
          username: this.selectedCaregiver.email,
          // status: 1, // this.hospitalAdminToAdd.status,
          // gender: this.hospitalAdminToAdd.gender,
          // birthdate: this.hospitalAdminToAdd.birthdate,
          roleId: this.selectedCaregiver.roleId, // 6, // HOSPITAL_ADMIN_ROLE
          // userTimezone: this.hospitalAdminToAdd.userTimezone,
          // userSettingsJson: this.hospitalAdminToAdd.userSettingsJson,
          // language: this.hospitalAdminToAdd.language,
          hospitalId: this.selectedCaregiver.hospitalAssignedData.id,
          organizationId: this.selectedCaregiver.hospitalAssignedData.medicalInstitutionId,
        };

        await this.$store.dispatch('users/updateUser', body)
          .then((res) => {
            if (res) {
              showSuccessAlert(this.$t('success-operation'));
            //   this.getOrganizationAmdinUsers(); // reload the list of organization admins
            //   this.getHospitalAmdinsUsers(); // reload the list of hospital admins

              if (this.selectedCaregiver.roleId === 6) {
                this.getHospitalAmdinsUsers(); // reload the list of hospital admins
              } else if (this.selectedCaregiver.roleId === 8) {
                this.getOrganizationAmdinUsers(); // reload the list of organization admins
              } else if (this.selectedCaregiver.roleId === 7) {
                this.getCaregivers(); // reload the list of caregivers
              }
            } else {
                showErrorAlert(this.$t('failed-operation'));
            }
          });
      } else {
        this.$refs.form.validate();
      }
    },
    save (date) {
        this.$refs.menu.save(date);
    },
    closeDrawer () {
        this.$store.commit('users/SELECTED_HOSPITAL_ADMIN'); // set selectedHospitalAdmin = null
        this.$store.commit('users/SELECTED_ORGANIZATION_ADMIN'); // set selectedOrganizationAdmin = null
        this.$store.commit('users/SELECTED_CARE_GIVER'); // set selectedCaregiver = null

        this.$store.commit('SET_UPDATE_DETAILS_DRAWER', false);
        this.$store.commit('SET_EDIT_DETAILS_DRAWER', false);
    },
    async checkAndFixLocationDetails () {
        try {
            if (this.selectedHospitalAdmin.address !== '' && this.selectedHospitalAdmin.city !== '' && this.selectedHospitalAdmin.zip !== '' && this.selectedHospitalAdmin.state !== '' && this.selectedHospitalAdmin.country !== '') {
                var addressForValidation;
                addressForValidation = this.selectedHospitalAdmin.address + ', ' + this.selectedHospitalAdmin.city + ', ' + this.selectedHospitalAdmin.state + ' ' + this.selectedHospitalAdmin.zip + ', ' + this.selectedHospitalAdmin.country;
                const geoLocation = await this.$store.dispatch(
                    'googleAPI/getGeoLocation',
                    addressForValidation,
                );
                if (!geoLocation) {
                    // this.selectedMedicalInstitution.addressLatitude = 0.01;
                    // this.selectedMedicalInstitution.addressLongitude = 0.01;
                    return false;
                }
                if (geoLocation.address === undefined || geoLocation.address == null) {
                    // this.selectedMedicalInstitution.addressLatitude = 0.01;
                    // this.selectedMedicalInstitution.addressLongitude = 0.01;
                    return false;
                }

                const addressComponents = geoLocation.address;

                // this.selectedHospitalAdmin.addressLatitude = (geoLocation.lat === undefined || geoLocation.lat === null) ? 0.01 : geoLocation.lat;
                // this.selectedHospitalAdmin.addressLongitude = (geoLocation.lon === undefined || geoLocation.lon === null) ? 0.01 : geoLocation.lon;
                this.selectedHospitalOrOrganization.addressLatitude = (geoLocation.lat === undefined || geoLocation.lat === null) ? 0.01 : geoLocation.lat;
                this.selectedHospitalOrOrganization.addressLongitude = (geoLocation.lon === undefined || geoLocation.lon === null) ? 0.01 : geoLocation.lon;

                this.selectedHospitalAdmin.address = (geoLocation.display_name === undefined || geoLocation.display_name === null) ? this.selectedHospitalAdmin.address : geoLocation.display_name;
                this.selectedHospitalAdmin.city = addressComponents.city || addressComponents.village || addressComponents.suburb || 'N/A';
                this.selectedHospitalAdmin.state = addressComponents.state || 'N/A';
                this.selectedHospitalAdmin.country = addressComponents.country || 'N/A';
                this.selectedHospitalAdmin.zip = addressComponents.postcode || 'N/A';
                this.addressIsValid = true;
                this.timezone = addressComponents.timezone || 'N/A';
                return true;
            } else {
                this.selectedHospitalAdmin.addressLatitude = 0.01;
                this.selectedHospitalAdmin.addressLongitude = 0.01;
                return false;
            }
        } catch (error) {
            this.selectedHospitalAdmin.addressLatitude = 0.01;
            this.selectedHospitalAdmin.addressLongitude = 0.01;
            return false;
        }
    },
    async checkAndFixLocationDetailsForOrganizationAdmin () {
        try {
            if (this.selectedOrganizationAdmin.address !== '' && this.selectedOrganizationAdmin.city !== '' && this.selectedOrganizationAdmin.zip !== '' && this.selectedOrganizationAdmin.state !== '' && this.selectedOrganizationAdmin.country !== '') {
                var addressForValidation;
                addressForValidation = this.selectedOrganizationAdmin.address + ', ' + this.selectedOrganizationAdmin.city + ', ' + this.selectedOrganizationAdmin.state + ' ' + this.selectedOrganizationAdmin.zip + ', ' + this.selectedOrganizationAdmin.country;
                const geoLocation = await this.$store.dispatch(
                    'googleAPI/getGeoLocation',
                    addressForValidation,
                );
                if (!geoLocation) {
                    // this.selectedMedicalInstitution.addressLatitude = 0.01;
                    // this.selectedMedicalInstitution.addressLongitude = 0.01;
                    return false;
                }
                if (geoLocation.address === undefined || geoLocation.address == null) {
                    // this.selectedMedicalInstitution.addressLatitude = 0.01;
                    // this.selectedMedicalInstitution.addressLongitude = 0.01;
                    return false;
                }

                const addressComponents = geoLocation.address;

                this.selectedOrganizationAdmin.addressLatitude = (geoLocation.lat === undefined || geoLocation.lat === null) ? 0.01 : geoLocation.lat;
                this.selectedOrganizationAdmin.addressLongitude = (geoLocation.lon === undefined || geoLocation.lon === null) ? 0.01 : geoLocation.lon;
                this.selectedOrganizationAdmin.address = (geoLocation.display_name === undefined || geoLocation.display_name === null) ? this.selectedOrganizationAdmin.address : geoLocation.display_name;
                this.selectedOrganizationAdmin.city = addressComponents.city || addressComponents.village || addressComponents.suburb || 'N/A';
                this.selectedOrganizationAdmin.state = addressComponents.state || 'N/A';
                this.selectedOrganizationAdmin.country = addressComponents.country || 'N/A';
                this.selectedOrganizationAdmin.zip = addressComponents.postcode || 'N/A';
                this.addressIsValid = true;
                this.timezone = addressComponents.timezone || 'N/A';
                return true;
            } else {
                this.selectedOrganizationAdmin.addressLatitude = 0.01;
                this.selectedOrganizationAdmin.addressLongitude = 0.01;
                return false;
            }
        } catch (error) {
            return false;
        }
    },
    async checkAndFixLocationDetailsForPartner () {
      try {
        if (this.selectedPartner.address !== '' && this.selectedPartner.city !== '' && this.selectedPartner.zip !== '' && this.selectedPartner.state !== '' && this.selectedPartner.country !== '') {
          var addressForValidation;
          addressForValidation = this.selectedPartner.address + ', ' + this.selectedPartner.city + ', ' + this.selectedPartner.state + ' ' + this.selectedPartner.zip + ', ' + this.selectedPartner.country;
          const geoLocation = await this.$store.dispatch(
              'googleAPI/getGeoLocation',
              addressForValidation,
          );
          if (!geoLocation) {
            // this.selectedMedicalInstitution.addressLatitude = 0.01;
            // this.selectedMedicalInstitution.addressLongitude = 0.01;
            return false;
          }
          if (geoLocation.address === undefined || geoLocation.address == null) {
            // this.selectedMedicalInstitution.addressLatitude = 0.01;
            // this.selectedMedicalInstitution.addressLongitude = 0.01;
            return false;
          }

          const addressComponents = geoLocation.address;

          this.selectedPartner.addressLatitude = (geoLocation.lat === undefined || geoLocation.lat === null) ? 0.01 : geoLocation.lat;
          this.selectedPartner.addressLongitude = (geoLocation.lon === undefined || geoLocation.lon === null) ? 0.01 : geoLocation.lon;
          this.selectedPartner.address = (geoLocation.display_name === undefined || geoLocation.display_name === null) ? this.selectedPartner.address : geoLocation.display_name;
          this.selectedPartner.city = addressComponents.city || addressComponents.village || addressComponents.suburb || 'N/A';
          this.selectedPartner.state = addressComponents.state || 'N/A';
          this.selectedPartner.country = addressComponents.country || 'N/A';
          this.selectedPartner.zip = addressComponents.postcode || 'N/A';
          this.addressIsValid = true;
          this.timezone = addressComponents.timezone || 'N/A';
          return true;
        } else {
          this.selectedPartner.addressLatitude = 0.01;
          this.selectedPartner.addressLongitude = 0.01;
          return false;
        }
      } catch (error) {
        return false;
      }
    },
    async checkAndFixLocationDetailsForCaregiver () {
        try {
            if (this.selectedCaregiver.address !== '' && this.selectedCaregiver.city !== '' && this.selectedCaregiver.zip !== '' && this.selectedCaregiver.state !== '' && this.selectedCaregiver.country !== '') {
                var addressForValidation;
                addressForValidation = this.selectedCaregiver.address + ', ' + this.selectedCaregiver.city + ', ' + this.selectedCaregiver.state + ' ' + this.selectedCaregiver.zip + ', ' + this.selectedCaregiver.country;
                const geoLocation = await this.$store.dispatch(
                    'googleAPI/getGeoLocation',
                    addressForValidation,
                );
                if (!geoLocation) {
                    // this.selectedMedicalInstitution.addressLatitude = 0.01;
                    // this.selectedMedicalInstitution.addressLongitude = 0.01;
                    return false;
                }
                if (geoLocation.address === undefined || geoLocation.address == null) {
                    // this.selectedMedicalInstitution.addressLatitude = 0.01;
                    // this.selectedMedicalInstitution.addressLongitude = 0.01;
                    return false;
                }

                const addressComponents = geoLocation.address;

                this.selectedCaregiver.addressLatitude = (geoLocation.lat === undefined || geoLocation.lat === null) ? 0.01 : geoLocation.lat;
                this.selectedCaregiver.addressLongitude = (geoLocation.lon === undefined || geoLocation.lon === null) ? 0.01 : geoLocation.lon;
                this.selectedCaregiver.address = (geoLocation.display_name === undefined || geoLocation.display_name === null) ? this.selectedCaregiver.address : geoLocation.display_name;
                this.selectedCaregiver.city = addressComponents.city || addressComponents.village || addressComponents.suburb || 'N/A';
                this.selectedCaregiver.state = addressComponents.state || 'N/A';
                this.selectedCaregiver.country = addressComponents.country || 'N/A';
                this.selectedCaregiver.zip = addressComponents.postcode || 'N/A';
                this.addressIsValid = true;
                this.timezone = addressComponents.timezone || 'N/A';
                return true;
            } else {
                this.selectedCaregiver.addressLatitude = 0.01;
                this.selectedCaregiver.addressLongitude = 0.01;
                return false;
            }
        } catch (error) {
            return false;
        }
    },
  },
};
</script>
